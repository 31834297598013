export default function redirectRoutes() {
    const routes = [
        //Manual product redirects needs to be before automatic product redirects
        { from: '^/ohjeet$', to: '/usein-kysyttya' },
        { from: '^/ohjeet/maksaminen/onko-maksaminen-turvallista$', to: '/ohjeet/maksaminen/' },
        { from: '^/ohjeet/maksaminen/meinko-maksuni-lapi$', to: '/ohjeet/maksaminen/' },
        { from: '^/tilaaminen-ja-ostoskorin-toiminta$', to: '/ohjeet/tilaaminen' },
        { from: '^/ohjeet/tilaaminen/en-ole-varma-onnistuiko-tilaukseni$', to: '/ohjeet/tilaaminen' },
        { from: '^/ohjeet/tilaaminen/en-saanut-tilausvahvistusta$', to: '/ohjeet/tilaaminen' },
        { from: '^/ohjeet/tilaaminen/tarvitsen-kuitin-tilauksestani$', to: '/ohjeet/tilaaminen' },
        { from: '^/ohjeet/tilaaminen/en-saanut-kaikkia-tilaamiani-tuotteita$', to: '/ohjeet/tilaaminen' },
        { from: '^/ohjeet/tilaaminen/haluan-muuttaa-tilaukseni-tietoja-tuotteita$', to: '/ohjeet/tilaaminen' },
        { from: '^/ohjeet/tilaaminen/haluan-peruuttaa-tilaukseni$', to: '/ohjeet/tilaaminen' },
        { from: '^/ohjeet/tilaaminen/noutaminen$', to: '/ohjeet/tilaaminen' },
        { from: '^/ohjeet/toimitus/millaiset-toimituskulut-teilla-on$', to: '/ohjeet/toimitus' },
        { from: '^/ohjeet/toimitus/toimitusvaihtoehdot$', to: '/ohjeet/toimitus' },
        { from: '^/ohjeet/toimitus/kuinka-nopeasti-saan-tilaukseni$', to: '/ohjeet/toimitus' },
        { from: '^/ohjeet/toimitus/missa-tilaukseni-on$', to: '/ohjeet/toimitus' },
        { from: '^/ohjeet/toimitus/joulu-2021$', to: '/ohjeet/toimitus' },
        { from: '^/ohjeet/palautus-ja-vaihto/kuinka-pitka-vaihto-ja-palautusaika-teilla-on$', to: '/ohjeet/palautus-ja-vaihto' },
        { from: '^/ohjeet/palautus-ja-vaihto/kuinka-vaihdan-tilaamani-tuotteen-toiseen$', to: '/ohjeet/palautus-ja-vaihto' },
        { from: '^/ohjeet/palautus-ja-vaihto/milloin-saan-rahapalautuksen-itselleni$', to: '/ohjeet/palautus-ja-vaihto' },
        { from: '^/ohjeet/palautus-ja-vaihto/olen-palauttanut-tilaukseni-mita-teen-laskulle$', to: '/ohjeet/palautus-ja-vaihto' },
        { from: '^/ohjeet/takuu-vika-tuotteessa/minulle-saapunut-tuote-oli-rikki-mita-teen$', to: '/ohjeet/takuu-vika-tuotteessa' },
        { from: '^/ohjeet/takuu-vika-tuotteessa/minulle-saapunut-tuote-ei-sopinut-puhelimeeni$', to: '/ohjeet/takuu-vika-tuotteessa' },
        { from: '^/ohjeet/takuu-vika-tuotteessa/kuinka-pitka-takuu-tuotteilla-on-ja-mita-se-kattaa$', to: '/ohjeet/takuu-vika-tuotteessa' },
        { from: '^/ohjeet/alekoodit/mista-saan-alekoodin$', to: '/ohjeet/alekoodit' },
        { from: '^/ohjeet/alekoodit/miten-alekoodeja-kaytetaan$', to: '/ohjeet/alekoodit' },
        { from: '^/ohjeet/alekoodit/minulla-on-alekoodi-mutta-se-ei-toimi$', to: '/ohjeet/alekoodit' },
        { from: '^/ohjeet/vinkit/en-ole-varma-puhelimeni-mallista$', to: '/ohjeet/vinkit' },
        { from: '^/ohjeet/vinkit/kuinka-asennan-hydrogel-suojakalvon$', to: '/ohjeet/vinkit' },
        { from: '^/ohjeet/vinkit/liity-tukkuclubiin$', to: '/ohjeet/vinkit' },
        { from: '^/ohjeet/vinkit/tilaa-uutiskirje$', to: '/ohjeet/vinkit' },
        { from: '^/ohjeet/tietoa-yrityksesta/avainlippu-yritys$', to: '/ohjeet/tietoa-yrityksesta' },
        { from: '^/keta-me-olemme$', to: '/keita-me-olemme' },
        { from: '^/kuinka-asennan-suojalasin$', to: '/kuinka-asennan-panssarilasin' },
        { from: '^/suojakuoriaakkoset/bluetooth-handsfree$', to: '/lisatarvikkeet/autoilutarvikkeet/handsfreet' },
        { from: '^/suojakuoriaakkoset/bluetooth-kaiutin$', to: '' },
        { from: '^/suojakuoriaakkoset/bluetooth-kuulokkeet$', to: '/lisatarvikkeet/kuulokkeet/nappikuulokkeet' },
        { from: '^/suojakuoriaakkoset/celly$', to: '/puhelimet' },
        { from: '^/suojakuoriaakkoset/dbramante1928$', to: '/puhelimet' },
        { from: '^/suojakuoriaakkoset/grateq$', to: '/lisatarvikkeet/lataustarvikkeet?brand=368&currentPage=1' },
        { from: '^/suojakuoriaakkoset/handsfree$', to: '/lisatarvikkeet/autoilutarvikkeet/handsfreet' },
        { from: '^/suojakuoriaakkoset/htc$', to: '/puhelimet/htc' },
        { from: '^/suojakuoriaakkoset/huawei$', to: '/puhelimet/huawei' },
        { from: '^/suojakuoriaakkoset/ipad-kuoret$', to: '/tablet-suojat/apple' },
        { from: '^/suojakuoriaakkoset/ipad-laturi$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/lightning' },
        { from: '^/suojakuoriaakkoset/ipad-teline$', to: '/lisatarvikkeet/autoilutarvikkeet/autotelineet' },
        { from: '^/suojakuoriaakkoset/ipadin-suojakotelo$', to: '/tablet-suojat/apple' },
        { from: '^/suojakuoriaakkoset/jabra$', to: '' },
        { from: '^/suojakuoriaakkoset/jalaka$', to: '/lisatarvikkeet/muut-tarvikkeet/puhelinpidikkeet' },
        { from: '^/suojakuoriaakkoset/juoksuvyo$', to: '/lisatarvikkeet/urheilu-ja-ulkoilu' },
        { from: '^/suojakuoriaakkoset/kaapeli$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit' },
        { from: '^/suojakuoriaakkoset/kannettavan-laukku$', to: '/lisatarvikkeet/yleislaukut-ja-kotelot' },
        { from: '^/suojakuoriaakkoset/kestava-puhelin$', to: '/suojakuoriaakkoset/iskunkestava-puhelin' },
        { from: '^/suojakuoriaakkoset/kestava-alypuhelin$', to: '/suojakuoriaakkoset/iskunkestava-puhelin' },
        { from: '^/suojakuoriaakkoset/kingston$', to: '/lisatarvikkeet/muut-tarvikkeet/muistikortit' },
        { from: '^/suojakuoriaakkoset/krusell$', to: '/puhelimet' },
        { from: '^/suojakuoriaakkoset/kuoret$', to: '/puhelimet' },
        { from: '^/suojakuoriaakkoset/kuulokkeet$', to: '/lisatarvikkeet/kuulokkeet' },
        { from: '^/suojakuoriaakkoset/kannykan-kuoret$', to: '/suojakuoriaakkoset/kannykkakuoret' },
        { from: '^/suojakuoriaakkoset/kannykan-suojakuoret$', to: '/suojakuoriaakkoset/kannykkakuoret' },
        { from: '^/suojakuoriaakkoset/kasivarsikotelo$', to: '/lisatarvikkeet/urheilu-ja-ulkoilu' },
        { from: '^/suojakuoriaakkoset/langaton-kaiutin$', to: '' },
        { from: '^/suojakuoriaakkoset/langattomat-kuulokkeet$', to: '/lisatarvikkeet/kuulokkeet' },
        { from: '^/suojakuoriaakkoset/laturi$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/suojakuoriaakkoset/laturit$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/suojakuoriaakkoset/lightning$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/lightning' },
        { from: '^/suojakuoriaakkoset/lightning-kaapeli$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/lightning' },
        { from: '^/suojakuoriaakkoset/matkalaturi$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/suojakuoriaakkoset/micro-usb$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/micro-usb' },
        { from: '^/suojakuoriaakkoset/micro-usb-kaapeli$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/micro-usb' },
        { from: '^/suojakuoriaakkoset/micro-usb-laturi$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/micro-usb' },
        { from: '^/suojakuoriaakkoset/mujjo$', to: '/puhelimet' },
        { from: '^/suojakuoriaakkoset/nappikuulokkeet$', to: '/lisatarvikkeet/kuulokkeet' },
        { from: '^/suojakuoriaakkoset/nayton-korjaus$', to: '/suojakuoriaakkoset/naytto-rikki' },
        { from: '^/suojakuoriaakkoset/naytonsuoja$', to: '/panssarilasit' },
        { from: '^/suojakuoriaakkoset/nayton-suojakalvo$', to: '/puhelimet?product_type_filter=284&currentPage=1' },
        { from: '^/suojakuoriaakkoset/puhelimen-suojakotelot$', to: '/puhelimet?product_type_filter=262&currentPage=1' },
        { from: '^/suojakuoriaakkoset/puhelimien-kuoret$', to: '/puhelimet' },
        { from: '^/suojakuoriaakkoset/puhelimien-suojakuoret$', to: '/puhelimet' },
        { from: '^/suojakuoriaakkoset/puhelin-kuoret$', to: '/puhelimet' },
        { from: '^/suojakuoriaakkoset/puhelimen-lisavirtalahde$', to: '/lisatarvikkeet/lataustarvikkeet/varavirta-lahteet' },
        { from: '^/suojakuoriaakkoset/puhelimen-nayton-korjaus$', to: '/suojakuoriaakkoset/naytto-rikki' },
        { from: '^/suojakuoriaakkoset/puhelimen-suojakuoret$', to: '/puhelimet' },
        { from: '^/suojakuoriaakkoset/puhelimien-suojakotelo$', to: '/puhelimet?product_type_filter=262&currentPage=1' },
        { from: '^/suojakuoriaakkoset/Puro$', to: '/puhelimet' },
        { from: '^/suojakuoriaakkoset/rikkinainen-naytto$', to: '/suojakuoriaakkoset/naytto-rikki' },
        { from: '^/suojakuoriaakkoset/samsung$', to: '/puhelimet/samsung' },
        { from: '^/suojakuoriaakkoset/sony$', to: '/puhelimet/sony' },
        { from: '^/suojakuoriaakkoset/staattinen-kalvo$', to: '/puhelimet?product_type_filter=284&currentPage=1' },
        { from: '^/suojakuoriaakkoset/suojakalvo$', to: '/puhelimet?product_type_filter=284&currentPage=1' },
        { from: '^/suojakuoriaakkoset/suojakotelo$', to: '/puhelimet?product_type_filter=262&currentPage=1' },
        { from: '^/suojakuoriaakkoset/suojakuoret$', to: '/puhelimet' },
        { from: '^/suojakuoriaakkoset/tablet-kuoret$', to: '/tablet-suojat' },
        { from: '^/suojakuoriaakkoset/tabletin-kuoret$', to: '/tablet-suojat' },
        { from: '^/suojakuoriaakkoset/tablettiteline$', to: '/tablet-suojat' },
        { from: '^/suojakuoriaakkoset/tietokonelaukku$', to: '/lisatarvikkeet/yleislaukut-ja-kotelot' },
        { from: '^/suojakuoriaakkoset/usb-c$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/usb-c' },
        { from: '^/suojakuoriaakkoset/usb-johto$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit' },
        { from: '^/suojakuoriaakkoset/usb-kaapeli$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit' },
        { from: '^/suojakuoriaakkoset/usb-laturi$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/suojakuoriaakkoset/vesitiivis-puhelin$', to: '/lisatarvikkeet/yleislaukut-ja-kotelot' },
        { from: '^/suojakuoriaakkoset/virtapankki$', to: '/lisatarvikkeet/lataustarvikkeet/varavirta-lahteet' },
        { from: '^/suojakuoriaakkoset/vyokotelo$', to: '/lisatarvikkeet/yleislaukut-ja-kotelot/olka-ja-vyolaukut' },
        { from: '^/tilaajalahjat$', to: '' },
        { from: '^/case-joensuun-maila$', to: '' },
        { from: '^/kareliagolf$', to: '' },
        { from: '^/black-friday-2021$', to: '/black-friday' },
        { from: '^/black-friday-2022$', to: '/black-friday' },
        { from: '^/cyber-monday-2021$', to: '/cyber-monday' },
        { from: '^/cyber-monday-2022$', to: '/cyber-monday' },
        { from: '^/blogi/2021/04/14/toita-tarjolla/$', to: '/' },
        { from: '^/blogi/2018/03/22/esittelyssa-parhaat-samsung-galaxy-s9-kuoret/$', to: '/puhelimet/samsung/galaxy-s9-kuoret' },
        { from: '^/blogi/2020/11/18/parhaat-5g-puhelimet-jouluna-2020-alle-400e/$', to: '/' },
        { from: '^/blogi/2020/04/29/inkit-top-10-kevaan-savyja/$', to: '/inkit-kuoret' },
        { from: '^/blogi/2020/04/07/nain-onnistut-etatoissa/$', to: '/blogi' },
        { from: '^/blogi/2020/02/20/uutuus-inkit-card-pocket-korttitasku/$', to: '/lisatarvikkeet/inkit-lisatarvikkeet/inkit-korttitaskut' },
        { from: '^/blogi/2020/01/24/mita-mobiilitukusta-ostettiin-2019/$', to: '/blogi' },
        { from: '^/blogi/2019/11/28/valkoista-joulua-edeltaa-musta-perjantai/$', to: '/black-friday' },
        { from: '^/blogi/2019/11/06/sinkkujen-juhlaa-ja-lahjoja-minulta-minulle/$', to: '/blogi' },
        { from: '^/blogi/2019/10/11/inkit-top-10-klassista-mustavalkoista-ja-tummia-syksyn-savyja/$', to: '/inkit-kuoret' },
        { from: '^/blogi/2019/09/25/uutuus-popsocket-grip-puhelinpidike/$', to: '/lisatarvikkeet/popsockets-tuotteet' },
        { from: '^/blogi/2019/09/13/iphone-11-puhelimet-pahkinankuoressa/$', to: '/puhelimet/apple/iphone-11-kuoret' },
        { from: '^/blogi/2019/08/15/esittelyssa-uusi-inkit-style-suojakuori/$', to: '/inkit-kuoret' },
        { from: '^/blogi/2019/08/02/parhaat-halvat-puhelimet-2019-alle-200e/$', to: '/blogi' },
        { from: '^/blogi/2019/05/16/oneplus-7-7-pro-puhelimet-mita-uutta/$', to: '/puhelimet/oneplus/oneplus-7-kuoret' },
        { from: '^/blogi/2019/03/22/mika-ihmeen-5g/$', to: '/blogi' },
        { from: '^/blogi/2019/01/31/tietopaketti-samsung-galaxy-s10-puhelimista/$', to: '/puhelimet/samsung/galaxy-s10-kuoret' },
        { from: '^/blogi/2018/10/11/oneplus-6t-mita-uutuuksia-luvassa/$', to: '/puhelimet/oneplus/oneplus-6t-kuoret' },
        { from: '^/blogi/2018/09/28/5-parasta-keskihintaista-300e-400e-puhelinta-2018/$', to: '/' },
        { from: '^/blogi/2018/09/13/kannattaako-uusia-iphoneja-ostaa/$', to: '/puhelimet/apple' },
        { from: '^/blogi/2018/08/22/millaisia-ovat-uudet-iphonet-2018/$', to: '/puhelimet/apple' },
        { from: '^/blogi/2018/06/27/xiaomi-tulee-suomeen/$', to: '/puhelimet/xiaomi' },
        { from: '^/blogi/2018/06/01/kuumimmat-huawei-lisatarvikkeet-kesa-2018/$', to: '/puhelimet/huawei' },
        { from: '^/blogi/2018/05/15/oneplus-6-hetki-ennen-julkaisua/$', to: '/puhelimet/oneplus/oneplus-6-kuoret' },
        { from: '^/blogi/2018/04/18/huawein-uudet-p20-mallit-esittelyssa/$', to: '/puhelimet/huawei/p20-kuoret' },
        { from: '^/blogi/2018/04/12/huawei-puhelimet-2018/$', to: '/puhelimet/huawei' },
        { from: '^/blogi/2018/03/14/must-have-samsung-lisatarvikkeet-2018/$', to: '/puhelimet/samsung' },
        { from: '^/blogi/2018/02/21/kuumimmat-puhelinvalmistajat-2018-osa-1-samsungin-puhelimet/$', to: '/puhelimet/samsung' },
        { from: '^/blogi/2018/02/12/puhelimen-kuori-ja-tarviketrendit-vuonna-2018/$', to: '/blogi' },
        { from: '^/blogi/2018/02/02/puhelintrendit-vuonna-2018/$', to: '/blogi' },
        { from: '^/blogi/2018/01/22/viime-vuoden-kuumimmat-puhelinmallit-sijalla-1-apple-iphone-x/$', to: '/puhelimet/apple/iphone-x-xs' },
        { from: '^/blogi/2018/01/20/viime-vuoden-kuumimmat-puhelinmallit-sijalla-2-samsung-galaxy-s8/$', to: '/puhelimet/samsung/galaxy-s8-kuoret' },
        { from: '^/blogi/2018/01/19/viime-vuoden-kuumimmat-puhelinmallit-sijalla-3-oneplus-5t/$', to: '/puhelimet/oneplus/oneplus-5t-kuoret' },
        { from: '^/blogi/2018/01/18/viime-vuoden-kuumimmat-puhelinmallit-sijalla-4-huawei-honor-9/$', to: '/puhelimet/huawei/honor-9-kuoret' },
        { from: '^/blogi/2018/01/17/mitka-olivat-vuoden-2017-kuumimmat-puhelinmallit/$', to: '/blogi' },
        { from: '^/blogi/2017/11/02/ennakossa-huhuttu-oneplus-5t/$', to: '/puhelimet/oneplus/oneplus-5t-kuoret' },
        { from: '^/blogi/2017/09/27/apple-iphone-x-juhlistamassa-iphonen-10-vuotista-historiaa/$', to: '/puhelimet/apple/iphone-x-xs' },
        { from: '^/blogi/2017/09/25/iphone-8-plus-millainen-laite-onkaan-kyseessa/$', to: '/puhelimet/apple/iphone-8-plus' },
        { from: '^/blogi/2017/09/08/iphone-8-julkaistaan-pian-mita-on-luvassa/$', to: '/puhelimet/apple/iphone-8-kuoret' },
        { from: '^/blogi/2017/08/25/millainen-puhelin-kannattaisi-ostaa/$', to: '/blogi' },
        { from: '^/blogi/2017/08/08/minka-puhelimen-ostaisin-lapselle/$', to: '/blogi' },
        { from: '^/blogi/2017/06/20/mita-mukaan-matkalle/$', to: '/blogi' },
        { from: '^/blogi/2017/05/26/mita-ostaa-valmistujaislahjaksi-tai-kuinka-yllattaa-uusi-ylioppilas/$', to: '/blogi' },
        { from: '^/blogi/2017/04/11/parhaat-hbo-ja-netflix-sarjat-paasiaiseksi/$', to: '/blogi' },
        { from: '^/blogi/2017/03/07/naistenpaiva-on-nyt-naistenviikko/$', to: '/blogi' },
        { from: '^/blogi/2017/03/02/ajatuksia-etatyosta/$', to: '/blogi' },
        { from: '^/blogi/2017/02/10/normipaiva/$', to: '/blogi' },
        { from: '^/blogi/2017/01/04/paras-suoja-puhelimelle-armor-suojakuori/$', to: '/puhelimet' },
        { from: '^/blogi/2016/10/06/keita-me-oikein-olemme-kare/$', to: '/keita-me-olemme' },
        { from: '^/blogi/2016/10/06/keita-me-oikein-olemme-heikki/$', to: '/keita-me-olemme' },
        { from: '^/lisatarvikkeet/johdot-kaapelit-ja-laturit$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/lisatarvikkeet/johdot-kaapelit-ja-laturit/micro-usb-kaapelit$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/micro-usb' },
        { from: '^/lisatarvikkeet/johdot-kaapelit-ja-laturit/usb-c-kaapelit$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/usb-c' },
        { from: '^/lisatarvikkeet/johdot-kaapelit-ja-laturit/lightning$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/lightning' },
        { from: '^/lisatarvikkeet/johdot-kaapelit-ja-laturit/kaapelipidikkeet$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelipidikkeet' },
        { from: '^/lisatarvikkeet/laturit$', to: '/lisatarvikkeet/lataustarvikkeet/verkkolaturit' },
        { from: '^/lisatarvikkeet/laturit/langaton-lataus$', to: '/lisatarvikkeet/lataustarvikkeet/langattomat-laturit' },
        { from: '^/lisatarvikkeet/laturit/autolaturit$', to: '/lisatarvikkeet/lataustarvikkeet/autolaturit' },
        { from: '^/lisatarvikkeet/laturit/varavirtalahteet$', to: '/lisatarvikkeet/lataustarvikkeet/varavirtalahteet' },
        { from: '^/lisatarvikkeet/kuulokkeet/nappikuulokkeet$', to: '/lisatarvikkeet/kuulokkeet' },
        { from: '^/lisatarvikkeet/kuulokkeet/urheilukuulokkeet$', to: '/lisatarvikkeet/kuulokkeet' },
        { from: '^/lisatarvikkeet/kuulokkeet/bluetoothkuulokkeet$', to: '/lisatarvikkeet/kuulokkeet' },
        { from: '^/lisatarvikkeet/kuulokkeet/sankakuulokkeet$', to: '/lisatarvikkeet/kuulokkeet' },
        { from: '^/lisatarvikkeet/kuulokkeet/kaiuttimet$', to: '/' },
        { from: '^/lisatarvikkeet/muut-tarvikkeet$', to: '/lisatarvikkeet' },
        { from: '^/lisatarvikkeet/muut-tarvikkeet/popsockets$', to: '/lisatarvikkeet/popsockets-tuotteet' },
        { from: '^/lisatarvikkeet/muut-tarvikkeet/puhelimen-korttitaskut$', to: '/lisatarvikkeet/puhelimen-korttitaskut' },
        { from: '^/lisatarvikkeet/muut-tarvikkeet/magsafe-tarvikkeet$', to: '/lisatarvikkeet/apple-lisatarvikkeet/magsafe-tarvikkeet' },
        { from: '^/lisatarvikkeet/muut-tarvikkeet/airpods-kotelot$', to: '/lisatarvikkeet/kuulokkeet-ja-kuuloketarvikkeet/airpods-tarvikkeet' },
        { from: '^/lisatarvikkeet/muut-tarvikkeet/airtag-lisavarusteet$', to: '/lisatarvikkeet/apple-lisatarvikkeet/airtag-tarvikkeet' },
        { from: '^/lisatarvikkeet/muut-tarvikkeet/tietokonelaukut$', to: '/lisatarvikkeet/muut-tarvikkeet' },
        { from: '^/lisatarvikkeet/inkit-puhelinpidikkeet$', to: '/lisatarvikkeet/inkit-lisatarvikkeet/inkit-oterenkaat' },
        { from: '^/lisatarvikkeet/tuunaa-puhelimesi/ranne-ja-kaulanauhat$', to: '/lisatarvikkeet/tuunaa-puhelimesi/rannenauhat' },
        { from: '^/wishlist$', to: '/account/register' },
        { from: '^/customer/account/login/$', to: '/account/login' },
        { from: '^/customer/account/login$', to: '/account/login' },
        // More redirects requested by customer on 18.07.2023, MOT-306
        { from: '^/3in1-puhelimen-linssisetti-punainen$', to: '/' },
        { from: '^/alykellotarvikkeet?product_type_filter=263&amp;currentPage=1$', to: '/alykellotarvikkeet-ja-suojat' },
        { from: '^/am-all-in-one-nayton-puhdistussuihke-harmaa$', to: '/lisatarvikkeet/puhdistustarvikkeet' },
        { from: '^/am-nayton-puhdistusliinat-18kpl-pinkki$', to: '/lisatarvikkeet/puhdistustarvikkeet' },
        { from: '^/apple-watch-4-6-se-1-2-gen-40mm-spigen-proflex-ez-fit-naytonsuoja-asennuskehyksella-2kpl$', to: '/alykellotarvikkeet-ja-suojat' },
        { from: '^/asus-zenpad-3s-10-z500m-suojakotelo-punainen$', to: '/' },
        { from: '^/asus-zenpad-3s-10-z500m-suojakotelo-valkoinen$', to: '/' },
        { from: '^/belkin-usb-type-c-usb-a-2-0-kaapeli-1-8m-musta$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/blogi/2017/10/11/onko-sinulle-kaynyt-nain-10-tapaa-rikkoa-puhelin/$', to: '' },
        { from: '^/blogi/2020/03/11/lisatarvikkeet/lataustarvikkeet/varavirta-lahteet$', to: '/blogi/2020/03/11/varavirtalahde-lentokoneessa' },
        { from: '^/blogi/index.php/2016/11/24/millainen-on-paras-suoja-puhelimelle/$', to: '/blogi/2016/11/24/millainen-on-paras-suoja-puhelimelle' },
        {
            from: '^/blogi/index.php/2016/11/28/paras-suoja-puhelimelle-ultraohut-suojakuori/$',
            to: '/blogi/2016/11/28/paras-suoja-puhelimelle-ultraohut-suojakuori',
        },
        { from: '^/blogi/index.php/2017/01/04/paras-suoja-puhelimelle-armor-suojakuori/$', to: '/blogi' },
        { from: '^/bluedio-h-turbine-bluetooth-sankakuulokkeet-musta$', to: '/' },
        { from: '^/brandit/celly/celly-propower-usb-c-30w-pikalaturi-valkoinen$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/catalogsearch/result/?q=quick&amp;easy$', to: '/panssarilasit' },
        { from: '^/catalogsearch/result/?q=screenor premium$', to: '/panssarilasit' },
        { from: '^/catalogsearch/result/?q=ultraohut$', to: '/puhelimet' },
        { from: '^/catalogsearch/result/?q=ultraohut suojakuori$', to: '/puhelimet' },
        { from: '^/celly-autolaturi-micro-usb-universal-2-1a-musta$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/celly-pb6000-voimapankki-6000mah-musta$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/celly-verkkolaturi-micro-usb-2-1a-musta$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/customer/account/login/$', to: '/account/register' },
        { from: '^/opas-loyda-taydellinen-ranneke-alykelloosi$', to: '/opas-loyda-taydellinen-ranneke-alykelloosi' },
        { from: '^/search?q=vesitiivis suojapussi$', to: '/lisatarvikkeet/urheilu-ja-ulkoilu' },
        { from: '^/huawei-mate-10-lite-nayton-panssarilasi$', to: '/panssarilasit' },
        { from: '^/huawei-p30-quick-easy-panssarilasi$', to: '/panssarilasit' },
        { from: '^/huawei-psmart-shockproof-suojakuori-kirkas$', to: '/puhelimet' },
        { from: '^/keta-me-olemme$', to: '/keita-me-olemme' },
        { from: '^/kosketusnaeyttoekynae-matkamalli$', to: '/kosketusnayttokyna-matkamalli' },
        { from: '^/lisatarvikkeet/johdot-kaapelit-ja-laturit$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/lisatarvikkeet/johdot-kaapelit-ja-laturit/langaton-lataus$', to: '/lisatarvikkeet/lataustarvikkeet/langattomat-laturit' },
        { from: '^/lisatarvikkeet/johdot-kaapelit-ja-laturit/lightning$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/lightning' },
        { from: '^/lisatarvikkeet/johdot-kaapelit-ja-laturit/micro-usb-kaapelit$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/micro-usb' },
        { from: '^/lisatarvikkeet/johdot-kaapelit-ja-laturit/usb-c-kaapelit$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/usb-c' },
        { from: '^/lisatarvikkeet/kuulokkeet$', to: '/' },
        { from: '^/lisatarvikkeet/kuulokkeet/bluetoothkuulokkeet$', to: '/' },
        { from: '^/lisatarvikkeet/kuulokkeet/kaiuttimet$', to: '/' },
        { from: '^/lisatarvikkeet/muut-tarvikkeet/popsockets$', to: '/lisatarvikkeet/popsockets' },
        { from: '^/lisatarvikkeet/muut-tarvikkeet/popsockets-puhelinpidike$', to: '/lisatarvikkeet/popsockets' },
        { from: '^/lisatarvikkeet/popsockets-puhelinpidikkeet$', to: '/lisatarvikkeet/popsockets' },
        { from: '^/lisatarvikkeet/varavirta-lahteet$', to: '/lisatarvikkeet/lataustarvikkeet/varavirtalahteet' },
        { from: '^/muut$', to: '/lisatarvikkeet/' },
        { from: '^/muut/autoilutarvikkeet$', to: '/lisatarvikkeet/autoilutarvikkeet' },
        { from: '^/muut/autoilutarvikkeet/autolaturit$', to: '/lisatarvikkeet/autoilutarvikkeet/autolaturit' },
        { from: '^/muut/johdot-kaapelit-ja-laturit/$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/muut/johdot-kaapelit-ja-laturit/usb-c-kaapelit$', to: '/lisatarvikkeet/lataustarvikkeet/kaapelit/usb-c' },
        { from: '^/muut/muut-tarvikkeet/popsockets-puhelinpidike$', to: '/lisatarvikkeet/popsockets' },
        { from: '^/muut/puhelimen-kuoret-ja-panssarilasit$', to: '/' },
        { from: '^/muut/suojakalvot-ja-lasit/nayton-suojakalvot$', to: '/panssarilasit' },
        { from: '^/muut/suojakalvot-ja-lasit/nayton-suojalasit$', to: '/panssarilasit' },
        { from: '^/muut/varavirta-lahteet$', to: '/lisatarvikkeet/lataustarvikkeet/varavirtalahteet' },
        { from: '^/nahkainen-vyoekotelo-musta-iso$', to: '/lisatarvikkeet/urheilu-ja-ulkoilu' },
        { from: '^/oneplus-3-3t-koko-nayton-suojalasi-6279$', to: '/puhelimet/oneplus/oneplus-3-kuoret' },
        { from: '^/oneplus-3-3t-lastucover-suojakuori-kelo$', to: '/puhelimet/oneplus/oneplus-3-kuoret' },
        { from: '^/oneplus-3-lompakko-suojakotelo-sininen$', to: '/puhelimet/oneplus/oneplus-3-kuoret' },
        { from: '^/oneplus-3-ultraohut-suojakuori$', to: '/puhelimet/oneplus/oneplus-3-kuoret' },
        { from: '^/oneplus-warp-charge-30-power-verkkolaturi-valkoinen$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/popsockets-popclip-autoteline-musta$', to: '/lisatarvikkeet/popsockets' },
        { from: '^/popsockets-puhelinpidike$', to: '/lisatarvikkeet/popsockets' },
        { from: '^/powerstar-micro-usb-tekstiilikaapeli-1-5m-musta$', to: '/lisatarvikkeet/lataustarvikkeet' },
        { from: '^/puhelimen-nayton-vaihto/elastinen-liima-b-7000-mobiililaitteiden-korjaukseen-15ml$', to: '/varaosat/puhelimen-varaosat/puhelimen-tyokalut' },
        { from: '^/puhelimen-nayton-vaihto/kaksipuolinen-teippi-mobiililaitteille-2mm-levea$', to: '/varaosat/puhelimen-varaosat/puhelimen-tyokalut' },
        { from: '^/puhelimen-suojat/apple$', to: '/puhelimet/apple' },
        { from: '^/puhelimen-suojat/asus$', to: '/puhelimet/asus' },
        { from: '^/puhelimen-suojat/honor$', to: '/puhelimet/honor' },
        { from: '^/puhelimen-suojat/htc$', to: '/' },
        { from: '^/puhelimen-suojat/huawei$', to: '/puhelimet/huawei' },
        { from: '^/puhelimen-suojat/lenovo$', to: '/' },
        { from: '^/puhelimen-suojat/lg$', to: '/' },
        { from: '^/puhelimen-suojat/motorola$', to: '/puhelimet/motorola' },
        { from: '^/puhelimen-suojat/nokia$', to: '/puhelimet/nokia' },
        { from: '^/puhelimen-suojat/nothing$', to: '/puhelimet/nothing' },
        { from: '^/puhelimen-suojat/oneplus$', to: '/puhelimet/oneplus' },
        { from: '^/puhelimen-suojat/realme$', to: '/puhelimet/realme' },
        { from: '^/puhelimen-suojat/samsung$', to: '/puhelimet/samsung' },
        { from: '^/puhelimen-suojat/sony$', to: '/puhelimet/sony' },
        { from: '^/puhelimen-suojat/tcl$', to: '/puhelimet/tcl' },
        { from: '^/puhelimen-suojat/xiaomi$', to: '/puhelimet/xiaomi' },
        { from: '^/puhelimen-suojat?brand=372&amp;currentPage=1$', to: '/puhelimet' },
        { from: '^/puhelimen-suojat?brand=395&amp;currentPage=1$', to: '/puhelimet' },
        { from: '^/puhelimen-suojat?brand=453&amp;currentPage=1$', to: '/puhelimet' },
        { from: '^/puhelimen-suojat?brand=453&amp;product_type_filter=262&amp;currentPage=1$', to: '/puhelimet' },
        { from: '^/puhelimen-suojat?product_type_filter=262&amp;color_selection=1113&amp;currentPage=1$', to: '/puhelimet' },
        { from: '^/puhelimen-vesitiivis-suojapussi-l-17-5x-10-5cm$', to: '/lisatarvikkeet/urheilu-ja-ulkoilu' },
        { from: '^/puhelimet/Huawei/p30-lite-kuoret$', to: '/puhelimet/huawei/p30-lite' },
        { from: '^/puhelimet/OnePlus/nord-ce-2-lite-5g-kuoret$', to: '/puhelimet/oneplus/oneplus-nord-ce-2-lite-5g-kuoret' },
        { from: '^/puhelimet/Samsung/galaxy-a22-kuoret$', to: '/puhelimet/samsung/galaxy-a22-5g-kuoret' },
        { from: '^/puhelimet/Samsung/galaxy-a32-kuoret$', to: '/puhelimet/samsung/galaxy-a32-5g-kuoret' },
        { from: '^/puhelimet/Samsung/galaxy-a51-kuoret$', to: '/puhelimet/samsung/samsung-galaxy-a51-kuoret' },
        { from: '^/puhelimet/Samsung/galaxy-note-20-ultra-kuoret$', to: '/puhelimet/samsung/galaxy-note-20-ultra-5g-kuoret' },
        { from: '^/puhelimet/Samsung/galaxy-s21-ultra-kuoret$', to: '/puhelimet/samsung/galaxy-s21-ultra-kuoret' },
        { from: '^/puhelimet/Samsung/samsung-galaxy-s21-fe-kuoret$', to: '/puhelimet/samsung/galaxy-s21-fe-kuoret' },
        { from: '^/puhelimet/Xiaomi/Xiaomi Redmi Note 10 5G Ultraohut Suojakuori$', to: '/puhelimet/xiaomi/redmi-note-10-kuoret' },
        { from: '^/puhelimet/Xiaomi/Xiaomi Redmi Note 10 Pro Hydrogel Suojakalvo$', to: ' Kirkas$', to: '/puhelimet/xiaomi/redmi-note-10-pro-kuoret' },
        { from: '^/puhelimet/Xiaomi/redmi-9t-kuoret$', to: '/puhelimet/xiaomi/redmi-9t' },
        { from: '^/puhelimet/apple/iphone-14-max-kuoret$', to: '/puhelimet/apple/iphone-14-pro-max-kuoret' },
        { from: '^/puhelimet/apple/iphone-6-plus-6s-plus-kuoret/flip-cover-kotelot$', to: '/puhelimet/apple/iphone-6-plus-6s-plus-kuoret' },
        { from: '^/puhelimet/apple/iphone-6-plus-6s-plus-kuoret/naytonsuojat$', to: '/puhelimet/apple/iphone-6-plus-6s-plus-kuoret' },
        { from: '^/puhelimet/apple/iphone-7-kuoret/apple-iphone-7-ultraohut-suojakuori$', to: '/puhelimet/apple/iphone-7-kuoret' },
        { from: '^/puhelimet/apple/iphone-7-kuoret/johdot-kaapelit-ja-laturit$', to: '/puhelimet/apple/iphone-7-kuoret' },
        { from: '^/puhelimet/apple/iphone-7-kuoret/varavirta$', to: '/puhelimet/apple/iphone-7-kuoret' },
        { from: '^/puhelimet/honor/honor-magic4-lite-kuoret$', to: '/puhelimet/honor/magic4-lite-kuoret' },
        { from: '^/puhelimet/huawei/honor-5x$', to: '/' },
        { from: '^/puhelimet/huawei/honor-7/flip-cover-kotelot$', to: '/puhelimet/huawei/honor-7-kuoret' },
        { from: '^/puhelimet/huawei/honor-7/huawei-honor-7-naeytoen-suojalasi$', to: '/puhelimet/huawei/honor-7-kuoret' },
        { from: '^/puhelimet/huawei/honor-8$', to: '/puhelimet/huawei/honor-8-kuoret' },
        { from: '^/puhelimet/huawei/honor-8-lite-kuoret/lompakko-suojakotelot$', to: '/puhelimet/huawei/honor-8-lite-kuoret' },
        { from: '^/puhelimet/huawei/honor-8-lite-kuoret/naytonsuojat$', to: '/puhelimet/huawei/honor-8-lite-kuoret' },
        { from: '^/puhelimet/huawei/honor-9/huawei-honor-9-nayton-panssarilasi$', to: '/puhelimet/huawei/honor-9-kuoret' },
        { from: '^/puhelimet/huawei/honor-9/huawei-honor-9-ultraohut-suojakuori$', to: '/puhelimet/huawei/honor-9-kuoret' },
        { from: '^/puhelimet/huawei/nova-3-kuoret$', to: '/puhelimet/huawei/huawei-nova-3-kuoret' },
        { from: '^/puhelimet/jolla$', to: '/' },
        { from: '^/puhelimet/microsoft$', to: '/' },
        { from: '^/puhelimet/motorola/g60s$', to: '/puhelimet/motorola/moto-g60s-kuoret' },
        { from: '^/puhelimet/motorola/moto-e40s-kuoret$', to: '/' },
        { from: '^/puhelimet/motorola/moto-g31s-kuoret$', to: '/' },
        { from: '^/puhelimet/motorola/moto-g53-5g-kuoret$', to: '/puhelimet/motorola/motorola-moto-g53-5g-kuoret' },
        { from: '^/puhelimet/motorola/moto-g73-5g-kuoret$', to: '/puhelimet/motorola/motorola-moto-g73-5g-kuoret' },
        { from: '^/puhelimet/motorola/moto-g8-powers-kuoret$', to: '/puhelimet/motorola/moto-g8-power-kuoret' },
        { from: '^/puhelimet/motorola/razr-2022-kuoret$', to: '/puhelimet/motorola/motorola-razr-2022-kuoret' },
        { from: '^/puhelimet/nokia/lumia-730$', to: '/' },
        { from: '^/puhelimet/nokia/nokia-x10-5g$', to: '/puhelimet/nokia/nokia-x10-5g-kuoret' },
        { from: '^/puhelimet/nokia/nokia-x20-5g$', to: '/puhelimet/nokia/nokia-x20-5g-kuoret' },
        { from: '^/puhelimet/nokia/nokia-x20-5gs-kuoret$', to: '/puhelimet/nokia/nokia-x20-5g-kuoret' },
        { from: '^/puhelimet/oneplus/nord-2-kuoret$', to: '/puhelimet/oneplus/oneplus-nord-2-kuoret' },
        { from: '^/puhelimet/oneplus/nord-ce-2-kuoret$', to: '/puhelimet/oneplus/oneplus-nord-ce-2-kuoret' },
        { from: '^/puhelimet/oneplus/nord-ce-2-lite-5g-kuoret$', to: '/puhelimet/oneplus/oneplus-nord-ce-2-lite-5g-kuoret' },
        { from: '^/puhelimet/oneplus/nord-kuoret$', to: '/puhelimet/oneplus/oneplus-nord-kuoret' },
        { from: '^/puhelimet/oneplus/oneplus-nord-ce-2-5g-kuoret$', to: '/puhelimet/oneplus/oneplus-nord-ce-2-kuoret' },
        { from: '^/puhelimet/realme/realme-8-5g-plus-pro-kuoret$', to: '/puhelimet/realme/realme-8-5g-kuoret' },
        { from: '^/puhelimet/realme/realme-9-pro-pro-kuoret$', to: '/puhelimet/realme/realme-9-pro-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-a13s-kuoret$', to: '/puhelimet/samsung/galaxy-a13-5g-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-a20es-kuoret$', to: '/puhelimet/samsung/galaxy-a20e-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-a3-2016/samsung-galaxy-a3-2016-lompakko-suojakotelo-kukkapuu$', to: '/puhelimet/samsung/galaxy-a3-2016-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-a3-2016/samsung-galaxy-a3-2016-ultraohut-suojakuori$', to: '/puhelimet/samsung/galaxy-a3-2016-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-a40s-kuoret$', to: '/puhelimet/samsung/galaxy-a40-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-a42-5gs-kuoret$', to: '/puhelimet/samsung/galaxy-a42-5g-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-a5-2016$', to: '/puhelimet/samsung/galaxy-a5-2016-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-a51-kuoret$', to: '/puhelimet/samsung/samsung-galaxy-a51-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-a6-kuoret-99$', to: '/puhelimet/samsung/galaxy-a6-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-a8-kuoret-112$', to: '/puhelimet/samsung/galaxy-a8-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-j3-2017-kuoret/samsung-galaxy-j3-2017-dux-suojakotelo-sininen$', to: '/puhelimet/samsung/galaxy-j3-2017-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-j3-2017-kuoret/suojakuoret$', to: '/puhelimet/samsung/galaxy-j3-2017-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-note-8-kuoret/suojakuoret$', to: '/puhelimet/samsung/galaxy-note-8-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-note-8-kuoret/varavirta-lahteet$', to: '/puhelimet/samsung/galaxy-note-8-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s22-5g-kuoret$', to: '/puhelimet/samsung/galaxy-s22-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s22-plus-5g-kuoret$', to: '/puhelimet/samsung/galaxy-s22-plus-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s22-ultra-5g-kuoret$', to: '/puhelimet/samsung/galaxy-s22-ultra-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s23-5g-kuoret$', to: '/puhelimet/samsung/galaxy-s23-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s23-plus-5g-kuoret$', to: '/puhelimet/samsung/galaxy-s23-plus-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s23-ultra-5g-kuoret$', to: '/puhelimet/samsung/galaxy-s23-ultra-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s6$', to: '/puhelimet/samsung/galaxy-s6-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s6-edge$', to: '/' },
        { from: '^/puhelimet/samsung/galaxy-s6/suojakuoret$', to: '/puhelimet/samsung/galaxy-s6-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s7$', to: '/puhelimet/samsung/galaxy-s7-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s7-edge$', to: '/puhelimet/samsung/galaxy-s7-edge-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s7-edge/lompakko-suojakotelot$', to: '/puhelimet/samsung/galaxy-s7-edge-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s7-edge/samsung-galaxy-s7-edge-screenor-kaareva-suojalasi$', to: '/puhelimet/samsung/galaxy-s7-edge-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s7-edge/suojakuoret$', to: '/puhelimet/samsung/galaxy-s7-edge-kuoret' },
        {
            from: '^/puhelimet/samsung/galaxy-s7/johdot-kaapelit-ja-laturit/essentials-micro-usb-1m-kaapeli-valkoinen$',
            to: '/puhelimet/samsung/galaxy-s7-kuoret',
        },
        { from: '^/puhelimet/samsung/galaxy-s7/lompakko-suojakotelot$', to: '/puhelimet/samsung/galaxy-s7-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s7/samsung-galaxy-s-naeytoen-suojalasi$', to: '/puhelimet/samsung/galaxy-s7-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s8-kuoret/samsung-galaxy-s8-alcantara-cover-suojakuori-tummanharmaa$', to: '/puhelimet/samsung/galaxy-s8-kuoret' },
        {
            from: '^/puhelimet/samsung/galaxy-s8-kuoret/samsung-galaxy-s8-silicone-cover-suojakuori-vaaleanpunainen$',
            to: '/puhelimet/samsung/galaxy-s8-kuoret',
        },
        { from: '^/puhelimet/samsung/galaxy-s8-kuoret/suojakuoret$', to: '/puhelimet/samsung/galaxy-s8-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-s9-kuoret-115$', to: '/puhelimet/samsung/galaxy-s9-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-xcover-3/lompakko-suojakotelot$', to: '/puhelimet/samsung/galaxy-xcover-3-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-xcover-3/samsung-galaxy-xcover-3-naeytoen-suojalasi$', to: '/puhelimet/samsung/galaxy-xcover-3-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-xcover-3/suojakuoret$', to: '/puhelimet/samsung/galaxy-xcover-3-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-xcover-4/lompakko-suojakotelot$', to: '/puhelimet/samsung/galaxy-xcover-4-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-xcover-4/samsung-galaxy-xcover-4-naeytoen-suojalasi$', to: '/puhelimet/samsung/galaxy-xcover-4-kuoret' },
        { from: '^/puhelimet/samsung/galaxy-xcover-4/suojakuoret$', to: '/puhelimet/samsung/galaxy-xcover-4-kuoret' },
        { from: '^/puhelimet/sony/xperia-x/sony-xperia-x-armor-suojakuori-h$', to: '/puhelimet/sony/xperia-x-kuoret' },
        { from: '^/puhelimet/sony/xperia-x/sony-xperia-x-lompakko-suojakotelo-pinkki$', to: '/puhelimet/sony/xperia-x-kuoret' },
        { from: '^/puhelimet/sony/xperia-x/sony-xperia-x-naeytoen-suojalasi$', to: '/puhelimet/sony/xperia-x-kuoret' },
        { from: '^/puhelimet/sony/xperia-x/sony-xperia-x-s-line-suojakuori-musta$', to: '/puhelimet/sony/xperia-x-kuoret' },
        { from: '^/puhelimet/sony/xperia-xz/lompakko-suojakotelot$', to: '/puhelimet/sony/xperia-xz-xzs-kuoret' },
        { from: '^/puhelimet/sony/xperia-xz/sony-xperia-xz-dux-suojakotelo-sininen$', to: '/puhelimet/sony/xperia-xz-xzs-kuoret' },
        { from: '^/puhelimet/sony/xperia-xz/sony-xperia-xz-naeytoen-suojalasi$', to: '/puhelimet/sony/xperia-xz-xzs-kuoret' },
        { from: '^/puhelimet/sony/xperia-xz/sony-xperia-xz-ultraohut-suojakuori-kirkas$', to: '/puhelimet/sony/xperia-xz-xzs-kuoret' },
        { from: '^/puhelimet/sony/xperia-xz1/sony-xperia-xz1-armor-suojakuori-punainen$', to: '/puhelimet/sony/xperia-xz1-kuoret' },
        { from: '^/puhelimet/sony/xperia-xz1/sony-xperia-xz1-nayton-panssarilasi$', to: '/puhelimet/sony/xperia-xz1-kuoret' },
        { from: '^/puhelimet/xiaomi/11t-5gs-kuoret$', to: '/puhelimet/xiaomi/11t-5g-kuoret' },
        { from: '^/puhelimet/xiaomi/12-12xs-kuoret$', to: '/puhelimet/xiaomi/12-12x-kuoret' },
        { from: '^/puhelimet/xiaomi/12-Lite -kuoret$', to: '/puhelimet/xiaomi/12-lite-kuoret' },
        { from: '^/puhelimet/xiaomi/12-pros-kuoret$', to: '/puhelimet/xiaomi/12-pro-kuoret' },
        { from: '^/puhelimet/xiaomi/12t-12t-pro-kuoret$', to: '/puhelimet/xiaomi/12t-12tpro-kuoret' },
        { from: '^/puhelimet/xiaomi/Xiaomi Redmi Note 10 5G Ultraohut Suojakuori$', to: '/puhelimet/xiaomi/redmi-note-10-kuoret' },
        { from: '^/puhelimet/xiaomi/Xiaomi Redmi Note 10 Pro Hydrogel Suojakalvo$', to: ' Kirkas$', to: '/puhelimet/xiaomi/redmi-note-10-pro-kuoret' },
        { from: '^/puhelimet/xiaomi/mi-11-lite-5gs-kuoret$', to: '/puhelimet/xiaomi/mi-11-lite-5g-kuoret' },
        { from: '^/puhelimet/xiaomi/redmi-10cs-kuoret$', to: '/puhelimet/xiaomi/redmi-10c-kuoret' },
        { from: '^/puhelimet/xiaomi/redmi-9as-kuoret$', to: '/puhelimet/xiaomi/redmi-9a-kuoret' },
        { from: '^/puhelimet/xiaomi/redmi-9s-kuoret$', to: '/puhelimet/xiaomi/redmi-9-kuoret' },
        { from: '^/puhelimet/xiaomi/redmi-note-10-5g-kuoret$', to: '/puhelimet/xiaomi/redmi-note-10-kuoret' },
        { from: '^/puhelimet/xiaomi/redmi-note-10-kuoret/xiaomi-redmi-note-10-5g-candy-suojakuori-vihrea$', to: '/puhelimet/xiaomi/redmi-note-10-kuoret' },
        { from: '^/puhelimet/xiaomi/redmi-note-8-pros-kuoret$', to: '/puhelimet/xiaomi/redmi-note-8-pro-kuoret' },
        { from: '^/puhelimet/zte$', to: '/' },
        { from: '^/puro-fast-charger-varavirtalahde-9000-mah-musta$', to: '/lisatarvikkeet/lataustarvikkeet/varavirtalahteet' },
        { from: '^/samsung-galaxy-a5-2017-suojakalvo-kirkas-2-kpl$', to: '' },
        { from: '^/samsung-galaxy-s6-screenor-premium-naeytoen-suojalasi$', to: '/panssarilasit' },
        { from: '^/samsung-wireless-charger-convertible-langaton-latausalusta-musta$', to: '/lisatarvikkeet/lataustarvikkeet/langattomat-laturit' },
        { from: '^/sony-xperia-x-compact-quick-easy-panssarilasihttps://mobiilitukku.fi/sony-xperia-x-compact-quick-easy-panssarilasi$', to: '/' },
        { from: '^/sony-xperia-xa2-dux-suojakotelo-musta$', to: '/puhelimet/sony/xperia-xa2-kuoret' },
        { from: '^/sony-xperia-xz-naeytoen-suojalasi$', to: '/puhelimet/sony/xperia-xz-xzs-kuoret' },
        { from: '^/sony-xperia-xz1-compact-nayton-panssarilasi$', to: '/puhelimet/sony/xperia-xz1-compact-kuoret' },
        { from: '^/tablet-suojat?product_type_filter=278&amp;currentPage=1$', to: '/tabletit' },
        { from: '^/tabletit/apple/ipad-10-9-2022-suojakotelot$', to: '/tabletit/apple/ipad-10-9-2022-kotelot' },
        { from: '^/tabletit/huawei/mediapad-t3-7-suojakotelot-kuoret/autoilutarvikkeet$', to: '/tabletit/huawei/mediapad-t3-7-suojakotelot-kuoret' },
        { from: '^/tabletit/huawei/mediapad-t3-7-suojakotelot-kuoret/varavirta$', to: '/tabletit/huawei/mediapad-t3-7-suojakotelot-kuoret' },
        { from: '^/tabletit/huawei/mediapad-t3-8-kotelot-kuoret/johdot-kaapelit-ja-laturit$', to: '/tabletit/huawei/mediapad-t3-8-kotelot-kuoret' },
        { from: '^/tabletit/lenovo/tab-m10-3rd-gen-suojakotelot$', to: '/' },
        { from: '^/tabletit/lenovo/tab-p11-2nd-gen-suojakotelot$', to: '/tabletit/lenovo/tab-p11-2nd-gen-kotelot' },
        { from: '^/tabletit/lenovo/tab-p11-pro-2nd-gen-suojakotelot$', to: '/tabletit/lenovo/tab-p11-pro-2nd-gen-kotelot' },
        { from: '^/tabletit/nokia/nokia-21-suojakotelot$', to: '/tabletit/nokia/nokia-t21-kotelot' },
        { from: '^/transcend-micro-sdhc-muistikortti-class-10-32gb$', to: '/lisatarvikkeet/muut-tarvikkeet' },
        // More redirects requested by customer on 24.01.2025, MOT-587
        { from: '^/tabletit/apple/ipad-2-3-4$', to: '/tabletit/apple' },
        { from: '^/tabletit/apple/ipad-mini$', to: '/tabletit/apple' },
        { from: '^/tabletit/apple/ipad-mini-2-3$', to: '/tabletit/apple' },
        { from: '^/tabletit/apple/ipad-mini-2019-kotelot$', to: '/tabletit/apple' },
        { from: '^/tabletit/apple/ipad-mini-4-kotelot$', to: '/tabletit/apple' },
        { from: '^/tabletit/apple/ipad-pro-12-9-2018-kotelot$', to: '/tabletit/apple' },
        { from: '^/tabletit/huawei/matepad-t10s-kotelot$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/matepad-t10-kotelot$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-t1-8-0$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-t1-10$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-t2-7$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-t2-10$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-t3-7-suojakotelot-kuoret$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-t3-8-kotelot-kuoret$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-t5-10-suojakotelot$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-m2-8$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-m2-10-0$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-m3-lite-10$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-m3-lite-8-kotelot$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-m3-8$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-m5-8-4$', to: '/tabletit/huawei' },
        { from: '^/tabletit/huawei/mediapad-m5-10-suojakotelot$', to: '/tabletit/huawei' },
        { from: '^/tabletit/lenovo/yoga-tab-13-kotelot$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/yoga-tab-3-plus-10-1-kotelot$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/yoga-tab-3-pro$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/yoga-tab-3-10-1$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/tab-2-a8-50-8-0$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/tab-2-a10-30-10-1$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/tab-2-a10-70-10-1$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/tab-3-a7-10f-7$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/tab3-a730-7$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/tab4-8-plus-kotelot$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/lenovo-tab4-10$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/tab-e10-kotelot$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/tab-e10-kotelot$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/tab-p10-kotelot$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/tab-p11-pro-kotelot$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/lenovo/tab-p11-pro-2nd-gen-kotelot$', to: '/tabletit/lenovo' },
        { from: '^/tabletit/samsung/galaxy-tab-s4-10-5-suojakotelot$', to: '/tabletit/samsung' },
        { from: '^/tabletit/samsung/samsung-galaxy-tab-s3-9-7$', to: '/tabletit/samsung' },
        { from: '^/tabletit/samsung/galaxy-tab-s2-9-7$', to: '/tabletit/samsung' },
        { from: '^/tabletit/samsung/galaxy-tab-s2-8-0-kotelot$', to: '/tabletit/samsung' },
        { from: '^/tabletit/samsung/galaxy-tab-4-8-0$', to: '/tabletit/samsung' },
        { from: '^/tabletit/asus/asus-zenpad-10-suojakotelot-ja-kuoret$', to: '/' },
        { from: '^/tabletit/asus/asus-zenpad-3s-10-kotelot$', to: '/' },
        { from: '^/tabletit/asus/asus-zenpad-3s-10-z500kl-kotelot$', to: '/' },
        { from: '^/puhelimet/apple/iphone-6-plus-6s-plus-kuoret$', to: '/puhelimet/apple' },
        { from: '^/puhelimet/samsung/galaxy-note-8-kuoret$', to: '/puhelimet' },
        { from: '^/puhelimet/samsung/galaxy-note9-kuoret$', to: '/puhelimet' },
        { from: '^/puhelimet/samsung/galaxy-note-10-kuoret$', to: '/puhelimet' },
        { from: '^/puhelimet/samsung/galaxy-note-10-plus-kuoret$', to: '/puhelimet' },
        { from: '^/puhelimet/samsung/galaxy-s5-s5-neo-kuoret$', to: '/puhelimet' },
        { from: '^/puhelimet/samsung/galaxy-s6-kuoret$', to: '/puhelimet' },
        { from: '^/puhelimet/samsung/galaxy-s7-edge-kuoret$', to: '/puhelimet' },
        { from: '^/puhelimet/samsung/galaxy-s7-kuoret$', to: '/puhelimet' },
        { from: '^/puhelimet/samsung/galaxy-s8-plus-kuoret$', to: '/puhelimet' },
        { from: '^/puhelimet/samsung/galaxy-j4-plus-kuoret$', to: '/puhelimet' },
        { from: '^/puhelimet/samsung/galaxy-a3-kuoret$', to: '/puhelimet' },
        { from: '^/puhelimet/samsung/galaxy-a3-2016-kuoret$', to: '/puhelimet' },
        { from: '^/puhelimet/samsung/galaxy-a5-2016-kuoret$', to: '/puhelimet' },
        { from: '^/puhelimet/huawei/y5p-kuoret$', to: '/puhelimet/huawei' },
        { from: '^/puhelimet/huawei/y5-2019-kuoret$', to: '/puhelimet/huawei' },
        { from: '^/puhelimet/huawei/p10-kuoret$', to: '/puhelimet/huawei' },
        { from: '^/puhelimet/huawei/honor-6x-kuoret$', to: '/puhelimet/huawei' },
        { from: '^/puhelimet/huawei/honor-6a-kuoret$', to: '/puhelimet/huawei' },
        { from: '^/puhelimet/huawei/honor-7-lite-kuoret$', to: '/puhelimet/huawei' },
        { from: '^/puhelimet/huawei/honor-7-kuoret$', to: '/puhelimet/huawei' },
        { from: '^/puhelimet/huawei/honor-7a-kuoret$', to: '/puhelimet/huawei' },
        { from: '^/puhelimet/huawei/honor-8s-kuoret$', to: '/puhelimet/huawei' },
        { from: '^/puhelimet/xiaomi/pocophone-f1-kuoret$', to: '/puhelimet/xiaomi' },
        { from: '^/puhelimet/xiaomi/poco-f2-pro-kuoret$', to: '/puhelimet/xiaomi' },
        { from: '^/puhelimet/xiaomi/poco-f5-pro-kuoret$', to: '/puhelimet/xiaomi' },
        { from: '^/puhelimet/xiaomi/poco-f5-kuoret$', to: '/puhelimet/xiaomi' },
        { from: '^/puhelimet/xiaomi/poco-f4-gt-5g-kuoret$', to: '/puhelimet/xiaomi' },
        { from: '^/puhelimet/xiaomi/poco-x4-pro-5g-kuoret$', to: '/puhelimet/xiaomi' },
        { from: '^/puhelimet/xiaomi/mi-9t-kuoret$', to: '/puhelimet/xiaomi' },
        { from: '^/puhelimet/xiaomi/mi-9t-pro-kuoret$', to: '/puhelimet/xiaomi' },
        { from: '^/puhelimet/xiaomi/mi-note-10-mi-note-10-pro-kuoret$', to: '/puhelimet/xiaomi' },
        { from: '^/puhelimet/xiaomi/mi-11-ultra-5g-kuoret$', to: '/puhelimet/xiaomi' },
        { from: '^/puhelimet/nokia/nokia-4-2-kuoret$', to: '/puhelimet/nokia' },
        { from: '^/puhelimet/nokia/nokia-7-plus-kuoret$', to: '/puhelimet/nokia' },
        { from: '^/puhelimet/nokia/nokia-8-kuoret$', to: '/puhelimet/nokia' },
        { from: '^/puhelimet/nokia/nokia-9-pureview-kuoret$', to: '/puhelimet/nokia' },
        { from: '^/puhelimet/nokia/nokia-g10-kuoret$', to: '/puhelimet/nokia' },
        { from: '^/puhelimet/motorola/edge-30-neo-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/edge-30-ultra-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/edge-30-ultra-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/motorola-edge-20-lite-5g-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/edge-40-5g-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/edge-40-pro-5g-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/moto-g-5g-plus-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/moto-g7-play-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/moto-g8-plus-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/moto-g8-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/moto-g9-power-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/moto-g9-play-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/moto-g52-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/moto-g62-5g-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/moto-g200-5g-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/moto-e6i-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/moto-e7-plus-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/moto-e7i-power-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/moto-e7-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/motorola-razr-2022-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/sony/xperia-l4-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/sony/xperia-l3-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/sony/xperia-xz-xzs-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/sony/xperia-xz-premium-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/sony/xperia-xz1-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/sony/xperia-xz2-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/sony/xperia-xz3-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/sony/xperia-x-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/sony/xperia-xa1-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/sony/xperia-xa2-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/sony/xperia-5-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/sony/xperia-1-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/asus/zenfone-6-kuoret$', to: '/puhelimet/asus' },
        { from: '^/puhelimet/asus/zenfone-7-pro-kuoret$', to: '/puhelimet/asus' },
        { from: '^/puhelimet/asus/zenfone-7-kuoret$', to: '/puhelimet/asus' },
        { from: '^/puhelimet/realme/realme-8-5g-kuoret$', to: '/' },
        { from: '^/puhelimet/tcl/tcl-20r-5g-kuoret$', to: '/' },
        { from: '^/puhelimet/samsung/galaxy-note-20-ultra-5g-kuoret$', to: '/puhelimet/samsung' },
        { from: '^/puhelimet/samsung/galaxy-note-20-5g-kuoret$', to: '/puhelimet/samsung' },
        { from: '^/puhelimet/motorola/moto-e6s-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/motorola-edge-20-pro-5g-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/motorola/motorola-edge-20-5g-kuoret$', to: '/puhelimet/motorola' },
        { from: '^/puhelimet/sony/xperia-xz2-compact-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/sony/xperia-xz1-compact-kuoret$', to: '/puhelimet/sony' },
        { from: '^/puhelimet/asus/zenfone-9-5g-kuoret$', to: '/puhelimet/asus' },
        { from: '^/puhelimet/samsung/galaxy-a24-kuoret$', to: '/puhelimet/samsung' },
        { from: '^/tabletit/samsung/galaxy-tab-s8-ultra-14-6-suojakotelot$', to: '/tabletit/samsung' },
        { from: '^/tabletit/lenovo/tab4-10-plus$', to: '/tabletit/lenovo' },
    ];

    return routes;
}
